<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування меню" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs4" v-if="tabValue === 0">
                <va-input
                  label="Name *"
                  v-model="name"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.name"
                  :error="!!errors.name"
                  :error-messages="errors.name"
                />
                <va-input
                  label="Alias"
                  v-model="alias"
                  @input="delete errors.alias"
                  :messages="['The recommended number of characters is 15']"
                  :error="!!errors.alias"
                  :error-messages="errors.alias"
                />
                <va-input
                  label="Position"
                  v-model.number="position"
                  @input="delete errors.position"
                  :error="!!errors.position"
                  :error-messages="errors.position"
                />
                <va-toggle label="Published" small v-model="published"/>
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Title"
                  v-model="seoTitle"
                />
                <va-input
                  label="Description"
                  v-model="seoDescription"
                />
                <va-input
                  label="Keywords"
                  v-model="seoKeywords"
                />
                <div style="margin-bottom: 15px;">
                  <ckeditor v-model="seoText" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <!-- <span>Image</span>
                <va-card class="mb-3" title="Image">
                  <div v-if="seoImageExisting">
                    <a style="display: block;" target="_blank" :href="seoImageExisting"><img style="max-width: 400px;" :src="seoImageExisting"></a>
                    <div><va-button small color="danger" @click="seoImageExisting = deleteImage(seoImageId) ? '' : seoImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="seoImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('image', 'SeoEntityImage', 'SeoEntity[image]', seoImage[0], 'common\\models\\SeoEntity')">Завантажити</va-button>
                </va-card> -->
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import Vue from 'vue'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      name: '',
      alias: '',
      position: '',
      published: false,

      seoId: '',
      seoTitle: '',
      seoDescription: '',
      seoKeywords: '',
      seoText: '',
      seoImage: [],
      seoImageExisting: '',
      seoImageId: '',
      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      errors: [],

      tabTitles: ['Main', 'Seo'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (attr, entityAttribute, param, file, model = '') {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('model_name', model)
      formData.append('attribute', attr)
      formData.append(param, file)
      formData.append('file_id', '0')
      formData.append('multiple', '0')
      formData.append('id', this.seoId)
      formData.append('entity_attribute', entityAttribute)

      axios.post(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/upload/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      axios.put(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/menu/${this.$attrs.id}`, {
        main: {
          id: this.$attrs.id,
          name: this.name,
          alias: this.alias,
          position: this.position,
          published: !!this.published,
        },
        seo: {
          meta_title: this.seoTitle,
          meta_description: this.seoDescription,
          meta_keywords: this.seoKeywords,
          text: this.seoText,
          image: this.seoImage || null,
        },
      })
        .then(response => {
          console.log(response)
          this.$router.push({ name: 'prylukyagro-menu' })
          this.showToast('Success')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/menu/${this.$attrs.id}`)
        .then(response => {
          console.log('fetch pak menu')
          console.log(response.data)
          this.name = response.data.main.name
          this.alias = response.data.main.alias
          this.position = response.data.main.position
          this.published = !!response.data.main.published

          this.seoId = response.data.seo.id
          this.seoTitle = response.data.seo.meta_title
          this.seoDescription = response.data.seo.meta_description
          this.seoKeywords = response.data.seo.meta_keywords
          this.seoText = response.data.seo.text
          this.seoImageExisting = response.data.seo.image ? process.env.VUE_APP_PRYLUKYAGRO_ADMIN + response.data.seo.image : ''
          this.seoImageId = response.data.seo.imageId
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
